@import "./../common.scss";

.Header {
  z-index: 30;
  width: 100%;
  align-self: auto;
  color: #fff;

  &__menu-icon {
    width: 32px;
    height: 32px;
  }

  &__search {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    min-height: 80px;
    overflow-y: scroll;

    .form-group {
      position: relative;
      input {
        width: 100%;
        color: map-get($colors, grey04);
        border: none;
        @extend .Poppins, .font-weight-600;
        &::placeholder {
          color: map-get($colors, grey04);
          @extend .Poppins;
        }
      }
      input:focus {
        outline: none;
      }

      .form-label {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    .filters-container {
      overflow: hidden;

      ::-webkit-scrollbar {
        display: none; /* Chrome and Safari */
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }

    .filters {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      label {
        position: relative;
        padding: 16px 24px;
        background-color: map-get($colors, grey01);
        border: 1px solid map-get($colors, roseBtn);
        color: map-get($colors, grey05);
        border-radius: 30px;
        height: 40px;
        min-width: 120px;
        display: block;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-out;
      }

      .checked {
        background-color: map-get($colors, roseBtn);
        border: 1px solid map-get($colors, roseBtn);
        color: map-get($colors, grey01);
        transition: all 0.2s ease-out;
      }

      .checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .label {
        font-size: 16px;
        font-weight: 500;
        margin-top: 5px;
        display: block;
        @extend .Poppins;
        width: 100%;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
      }
    }
  }

  &__container {
    position: relative;
    top: 0;
    width: 100%;
    // margin-bottom: -$headerHeightMobile;
    z-index: 90;
    @include md {
      // margin-bottom: -$headerHeightDesktop;
    }
  }

  &__nav {
    height: $headerHeightMobile;
    @include lg {
      height: $headerHeightDesktop;
    }
  }

  &__ham-menu {
    display: flex;
    @include lg {
      display: none;
    }
  }

  .profile-dropdown {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    right: 0;
    width: 219px;
    animation-name: exa;
    opacity: 1;
    animation-duration: 0.8s;
    animation-timing-function: ease-out;

    .profile-arrow {
      width: 16px;
      height: 16px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      animation-name: exa;
      opacity: 1;
      animation-duration: 0.8s;
      animation-timing-function: ease-out;
      z-index: 1;
      transform: translateY(5%) translateX(-50%);
    }
    ul {
      li {
        width: 100%;
        display: block;
      }
    }
  }

  &__menu-groups {
    display: none;
    @include lg {
      display: flex;
      justify-content: space-between;
    }
    ul {
      display: flex;

      li {
        margin-right: map-get($space, "2");
        &:last-child {
          margin-right: map-get($space, "0");
        }
        a {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          font-size: 14px;
          font-weight: 500;

          .icon-label {
            max-width: 74px;
            max-height: 100%;
          }
        }
      }
    }
  }
  .menu-groups-layout {
    transform: translateX(0);
    @include lg {
      transform: translateX(-25%);
    }
  }

  &__mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    overflow-y: scroll;
    &-dropdown {
      margin-top: 13.4%;
      .dropdown-title {
        font-size: map-get($font-size, "20");
        font-weight: bold;
      }
      ul {
        li {
          a {
            font-size: map-get($font-size, "18");
            font-weight: normal;
            line-height: 1.11;
            color: map-get($colors, grey01);
          }
        }
      }
    }
  }

  &__mobile-nav-footer {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    width: 100%;
    span {
      font-family: "Poppins";
      font-size: map-get($font-size, "20");
      font-weight: normal;
      line-height: 1.11;
    }
  }

  &__mobile-menu {
    background-color: #ccc;
    width: 30px;
    height: 30px;
  }

  &__searchbox {
    background-color: #ccc;
    width: 30px;
    height: 30px;
  }

  &__profile-search {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 33px;
  }

  &__menu-desktop {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    height: 310px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    z-index: 2;

    &-open {
      opacity: 1;
      pointer-events: auto;
    }
    &-close {
      opacity: 0;
      pointer-events: none;
    }

    .card-logo-desktop {
      height: 30px;
      @include xl {
        height: 40px;
      }
    }

    .UpTitle {
      width: fit-content;
      span {
        padding-bottom: 3px;
        text-transform: uppercase;
        font-size: map-get($font-size, "14");
      }
    }

    .label-card {
      font-size: map-get($font-size, "16");
      font-weight: bold;
      line-height: 1.1;
      @include xl {
        font-size: map-get($font-size, "20");
      }
    }

    .field-menu-desktop {
      font-size: map-get($font-size, "20");
      font-weight: 500;
      line-height: 1.1;
      display: block;
      margin-bottom: map-get($space, "2");

      &:last-child {
        margin-bottom: map-get($space, "4");
      }
    }

    .cta-desktop {
      font-size: map-get($font-size, "20");
      font-weight: bold;
      line-height: 1.1;
      max-width: 100%;
      display: flex !important;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__card {
    position: relative;
    height: 250px;
    margin: 0 4px;
    width: 100%;

    @include lg {
      max-width: 200px;
    }

    &-content {
      width: 100%;
      height: 100%;
    }

    .img-container {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: top;
      }
    }

    .text-content {
      z-index: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      text-decoration: none;

      .btn {
        max-width: 190px;
        height: 36px;
        padding: 0;
        font-weight: bold;
      }
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      .bg {
        position: absolute;
        top: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
      .figure {
        position: absolute;
        z-index: 2;
        bottom: -10%;
        right: 0;
      }
    }
  }

  // Topbar
  &__topbar {
    height: $topBarHeight;
    font-size: map-get($font-size, "12");
    font-weight: 500;
    line-height: 1.1;
    @include md {
      font-size: map-get($font-size, "18");
      font-weight: 500;
      line-height: 1.1;
    }
    .cta {
      color: map-get($colors, rose02);
      border-bottom: 1px solid map-get($colors, rose01);
    }
    p {
      margin: 0;
    }
  }
}

.dropdown-menu {
  display: block;
}

@keyframes exa {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu-sticky {
  animation: exas 0.4s ease-out;
  background-color: map-get($colors, green03);
  position: sticky;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 0.4s forwards;

  .Header__nav {
    transition: all 0.4s ease-out;
    height: $headerHeightMobile;
    @include md {
      height: $headerHeightDesktop - 30px;
    }
  }

  .icon-responsive {
    @include md {
      width: 24px;
      height: 24px;
    }
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes exas {
  from {
    background-color: transparent;
  }
  to {
    background-color: map-get($colors, green01);
  }
}

.dropdown-selector-triangle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-98%) translateX(100%);
  width: 16px;
  height: 16px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  animation-name: exa;
  opacity: 1;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 1;
}

.calc-h-menu {
  height: calc(100vh - 60px);
}

.animation-search-section {
  // animation: animationSearchSection 0.2s ease-out;
}
@keyframes animationSearchSection {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.breadcrumbs {
  position: absolute;
  top: 100%;
  .text {
    font-size: 12px;
    @include md {
      font-size: 14px;
    }
  }
  .symbol {
    font-size: 8px;
    @include md {
      font-size: 10px;
    }
  }
}

.mobile-logo-nav {
  width: 60%;
  @include md {
  }
}

.desktop-logo-nav {
  max-height: 40px !important;
  max-width: 95px !important;
  padding: 0 0;
  @include md {
    padding: 8px 0;
    max-height: 60px !important;
    max-width: 100px !important;
  }
}

.user-area {
  overflow-y: scroll;
  &__link-desktop {
    text-decoration: none;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 16px;
  }
  &__item {
    display: inline;
    cursor: pointer;
    // border-bottom: 1px solid transparent;
    border-bottom: 1px solid transparent;
    @include lg {
      border-bottom: 1px solid #f2f2f2;
    }
    .list-item-text {
      margin: 0 !important;
      @include lg {
        padding: 0 20px !important;
      }
    }
    @include lg {
      // border-bottom: 1px solid map-get($colors, grey02);
    }
  }
  &__list {
    padding-bottom: 0 !important;
    margin-top: 40px !important;
    @include lg {
      margin-top: 0 !important;
    }
  }
  &__mobile {
    width: 85vw;
  }

  .no-p {
    padding: 0 !important;
  }

  &__user-name {
    font-weight: 600;
    font-size: 20px;
    font-family: "Poppins";
  }
}

.user-area__item.MuiListItem-gutters {
  padding: 20px 0;
}
.user-area__list {
  padding-top: 0 !important;
  border-bottom: 1px solid transparent;
  @include lg {
    border-bottom: 1px solid map-get($colors, grey02);
  }
  p {
    padding-bottom: 0 !important;
    font-size: 16px;
    color: #3b4154;
    font-weight: 500;
  }
}
