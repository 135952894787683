$font-size:(
     "1": 3.052rem,
     "2":2.441rem,
     "3":1.953rem,
     "4":1.563rem,
     "5":1.25rem,
     "6":1rem,
     "7":0.8rem,
     "8":0.64rem,
     "9":0.512rem,


     "10": 10px,
     "12": 12px,
     "14": 14px,
     "16": 16px,
     "18": 18px,
     "20": 20px,
     "24": 24px,
     "30": 30px,
     "40": 40px,
     "48": 48px,
     "56": 56px 
);

@each $key, $value in $font-size{
    .fs{
        &-#{$key}{
            font-size: $value !important;
        }
    }
}