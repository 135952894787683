table,
th,
td {
  border: 4px solid #f0f0f0;
  padding: 20px 16px;
  font-size: 1rem !important;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  line-height: 0.95rem !important;
  font-stretch: normal;
  letter-spacing: normal;
  border-collapse: collapse;
}

th {
  font-size: 1.1rem !important;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 1.6rem !important;
  font-stretch: normal;
  letter-spacing: normal;
  background-color: #f0f0f0;
}
