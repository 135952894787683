.form__group {
    position: relative;
}

.form__field {
    padding: 16px;
    border: 0;
    border: 1px solid map-get($colors, grey03);
    outline: none;
    position: relative;
    outline: 0;
    color: map-get($colors, grey04);
    font-size: 16px;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown ~ .form__label {
        font-size: 1rem;
        cursor: text;
        top: 50%;
        transform: translateY(-50%);
    }

    &-textarea{
        &:placeholder-shown ~ .form__label {
            font-size: 1rem;
            cursor: text;
            top: 0;
            transform: translateY(0);
        }
    }
}

.form__label {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    display: flex;
    transition: 0.2s;
    font-size: 0.8rem;
    color: map-get($colors, grey04);
}

.form__field:focus {
    ~ .form__label {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        display: block;
        transition: 0.2s;
        font-size: 0.8rem;
        color: map-get($colors, rose01);
    }
    //font-weight: bold;
    border-width: 1px;
    border-color: map-get($colors, rose01);
}



/* Reset input */
.form__field {
    &:required,
    &:invalid {
        box-shadow: none;
    }

    &:invalid {
        // color: map-get($colors, red01);
    }
}
