@import "./../common.scss";
.CategoriesGrid {
  .col-padding-cards {
    padding-right: 2.5px;
    padding-left: 2.5px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    @include md {
      padding-right: 5px;
      padding-left: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &__main-title {
    font-size: map-get($font-size, "20");
    font-weight: 600;
    @include md {
      font-size: map-get($font-size, "40");
    }
  }

  &__card {
    width: 100%;
    position: relative;
    // border: 2px solid transparent;
    // transition: all 0.45s ease-out;

    // &:hover {
    //   border: 2px solid map-get($colors, rose01);
    // }

    &--large {
      height: 262px;
      font-size: map-get($font-size, "20");
      font-weight: bold;
      @include md {
        height: 220px;
      }
      @include lg {
        height: 307px;
      }
    }
    &--small {
      height: 157px;
      font-size: map-get($font-size, "12");
      font-weight: bold;
      text-align: center;
      @include md {
        height: 220px;
        font-size: map-get($font-size, "20");
      }
      @include lg {
        height: 307px;
        font-size: map-get($font-size, "40");
      }
    }
  }
  &__logo {
    max-width: 108px;
    height: 25px;
    margin-left: map-get($space, "1");
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-position: left;
    }
  }
  a {
    text-decoration: none;
    color: map-get($colors, grey05);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    z-index: 2;
    &--small {
      padding: map-get($space, "2");
    }
    &--large {
      padding: map-get($space, "2");
    }
    .Passions__icon {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    img {
      object-position: top;
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: scale(1);
      transform-origin: 50% 50%;
      transition: transform 0.85s, visibility 0.45s ease-in;
    }
  }
  &__card-img--small {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    img {
      object-position: top;
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: scale(1);
      transform-origin: 50% 50%;
      transition: transform 0.85s, visibility 0.45s ease-in;
    }
  }

  &__icon {
    position: absolute;
    bottom: 0%;
    right: 0%;
    padding: 16px;
    img {
      margin-left: auto;
    }
  }
}

.CategoriesGrid__card:hover .CategoriesGrid__card-img img {
  transform: scale(2);
}
.CategoriesGrid__card:hover .CategoriesGrid__card-img--small img {
  transform: scale(2);
}

.card-main-title {
  font-size: 30px;
  line-height: 1;
  @include md {
    font-size: 40px;
  }
}
