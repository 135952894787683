$btn_fs_s: 14px;
$btn_fs_m: 18px;
$btn_fs_l: 20px;

.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: $btn_fs_m;
  max-width: 295px;
  height: 48px;
  padding: 24px 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 1.1;
  cursor: pointer;
  border-radius: 2px;

  @include md{
    font-size: 80% ;
  }
  @include lg{
    font-size: 90% ;
  }
  @include xl{
    font-size: 100% ;
  }

  &--centered {
    justify-content: center;
  }

  &__label{
    display: flex;
    height: 100%;
    align-items: center;
  }

  &--small {
    font-size: $btn_fs_s;
    padding: 18px 16px;
    max-width: 235px;
    height: 48px;
  }
  &--large {
    max-width: 338px;
    height: 64px;
    padding: 24px 16px;
    font-size: $btn_fs_l;
  }

  &__primary {
    background-color: map-get($colors, roseBtn);
    color: map-get($colors, grey01);
    @extend .ripple-primary;
  }

  &__outlined {
    background-color: transparent;
    color: map-get($colors, rose02);
    border: 1px solid map-get($colors, roseBtn);
  }

  &__disabled {
    background-color: map-get($colors, grey03);
    color: map-get($colors, grey01);
  }
  &__disabled-outlined {
    background-color: transparent;
    color: map-get($colors, grey03);

    border: 2px solid map-get($colors, grey03);
  }
}

/* Ripple effect connected to button with @extend */
.ripple-primary {
  background-position: center;
  transition: all 0.1s ease-out;
  transform: scale(1);

  &:hover {
    background: map-get($colors, roseBtn);
    @include lg{
      background: map-get($colors, rose03) radial-gradient(circle, transparent 1%,  map-get($colors, rose03) 1%) center/15000%;
    }
  }

  &:active {
    transform: scale(.99);
    background-color: map-get($colors, rose01);
    background-size: 100%;
    transition: all .1s ease-out;
  }
}