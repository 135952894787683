body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk * {
    font-size: x-small !important;
}

body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk .ot-sdk-container {
    padding: 0;
}

body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk .ot-sdk-row {
    display: flex;
}

body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-group-container {
    width: 100%;
}

body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-group-container #onetrust-policy {
    margin: 1.25em 0;
}

body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-group-container #onetrust-policy #onetrust-policy-title {
    margin-bottom: 0;
}

body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-button-group-parent {
    position: static;
    transform: none;
    padding: 0;
    padding: 0 36px;
    min-width: 514px;
    margin: auto;
}

body#deagostini.us #onetrust-banner-sdk #onetrust-button-group-parent {
    min-width: 330px;
}

body#deagostini.fr #onetrust-banner-sdk #onetrust-button-group {
    flex-flow: row !important;
}

body#deagostini.fr #onetrust-banner-sdk #onetrust-button-group button {
    margin-bottom: 5px !important;
    min-width: auto;
}

body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-pc-btn-handler {
    border-radius: 6px;
}

body#deagostini #onetrust-banner-sdk #onetrust-button-group #onetrust-pc-btn-handler {
    width: auto !important;
    margin-right: 0;
    min-width: 33%;
    max-width: none;
    background-color: #03c8b1 !important;
    color: #fff !important;
}

body#deagostini #onetrust-pc-sdk input[type="checkbox"]::after {
    display: none;
}

body#deagostini #onetrust-pc-sdk input[type="checkbox"] + label.ot-switch {
    top: auto;
}

@media only screen and (max-width: 1023px) {
    body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk .ot-sdk-row {
        display: block;
    }
}

@media only screen and (max-width: 549px) {
    body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-button-group-parent {
        min-width: 100% !important;
    }

    body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group {
        flex-flow: column !important;
    }

    body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group #onetrust-reject-all-handler,
    body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group #onetrust-accept-btn-handler {
        margin-right: 0 !important;
    }
}

@media (max-width: 480px) {
    body#deagostini.fr #onetrust-banner-sdk #onetrust-button-group,
    body#deagostini.be #onetrust-banner-sdk #onetrust-button-group {
        flex-flow: column !important;
    }

    body#deagostini.fr #onetrust-banner-sdk #onetrust-accept-btn-handler,
    body#deagostini.fr #onetrust-banner-sdk #onetrust-pc-btn-handler,
    body#deagostini.fr #onetrust-banner-sdk #onetrust-reject-all-handler,
    body#deagostini.be #onetrust-banner-sdk #onetrust-accept-btn-handler,
    body#deagostini.be #onetrust-banner-sdk #onetrust-pc-btn-handler,
    body#deagostini.be #onetrust-banner-sdk #onetrust-reject-all-handler {
        font-size: x-small !important;
        width: inherit !important;
    }
}

@media (min-width: 400px) {
    body#deagostini:not(.fr, .es, .be) #onetrust-banner-sdk .ot-sdk-container,
    body#deagostini:not(.fr, .es, .be) #onetrust-pc-sdk .ot-sdk-container,
    body#deagostini:not(.fr, .es, .be) #ot-sdk-cookie-policy .ot-sdk-container {
        width: 100%;
    }
}
