.phone-input {
  .MuiSelect-selectMenu {
    display: flex;
    align-content: center;
  }

  .MuiSelect-select {
    border-radius: 0;
    border-right: 0;
  }

  .MuiSelect-filled.MuiSelect-filled {
    color: #808080;
  }

  .icon {
    width: 23px;
    height: 15px;
    margin-right: 10px;
  }

  .MuiFilledInput-input {
    padding: 13px 12px 6px;
  }

  .MuiFilledInput-root {
    border-radius: 0;
  }

  .border-r-none {
    .MuiFilledInput-root {
      border-right: 0;
    }
  }

  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(14px, 7px) scale(0.55);
  }

  .MuiInputLabel-filled {
    transform: translate(14px, 7px) scale(0.55);
    color: #808080;
    text-transform: uppercase;
  }

  .column-input {
    .profile-phone {
      width: 100%;
    }

    .MuiFilledInput-root {
      border-left: 0;
    }

    .MuiInputBase-root {
      .MuiFilledInput-input {
        padding: 4px 12px 6px !important;
      }
    }
  }
}

.MuiListItemPhone {
  img {
    width: 23px;
    height: 15px;
    margin-right: 10px;
  }
}
