$headerHeightDesktop: 90px;
$headerHeightMobile: 66px;
$topBarHeight: 40px;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow: auto;
}

img {
  max-width: 100%;
  display: block;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.small {
  font-size: 85%;
}
.smallest {
  font-size: 45%;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.text-decoration {
  &-none {
    text-decoration: none;
  }
  &-underline {
    text-decoration: underline;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.no-overflow-body {
  overflow-y: hidden;
}

section {
  margin: 0;
}

.icon-btn {
  background-color: transparent;
  border: none;
}

.section-t-space {
  margin-top: 60px;
  @include md {
    margin-top: 80px;
  }
}

.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.shadow-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.shadow-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.shadow-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}


// Icon settings
.icon-animation {
  &:active {
    animation: iconAnimation 0.2s ease-out;
  }
}

@keyframes iconAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

$iconSize: (
  "8": 8px,
  "16": 16px,
  "24": 24px,
  "32": 32px,
  "48": 48px,
  "56": 56px
);

@each $key, $value in $iconSize {
  .icon {
    &-#{$key} {
      width: $value !important;
      height: $value !important;
    }
  }
}
.icon-responsive{
  width: 24px !important;
  height: 24px !important;
  @include md{
    width: 32px !important;
    height: 32px !important;
  }
}


// For HeroBannerModule
.object-desktop-top {
  @include md {
    object-position: top left !important;
  }
}
.object-desktop-center {
  @include md {
    object-position: center left !important;
  }
}
.object-desktop-bottom {
  @include md {
    object-position: bottom left !important;
  }
}
.object-mobile-top {
  object-position: top left;
}
.object-mobile-center {
  object-position: center left;
}
.object-mobile-bottom {
  object-position: bottom left;
}

@keyframes moduleSlideIn {
  from{
    padding-top: 100px;
    opacity: 0;
    visibility: hidden;
  }
  to{
    visibility: visible;
    padding-top: 0;
    opacity: 1;
  }
}

.module-visible{
  visibility: visible;
  opacity: 1;
  animation: moduleSlideIn 500ms ease-out;
  transition: opacity 500ms ease-out;
  transform: translateY(0);
}

.module-hidden{
  visibility: hidden;
  opacity: 0;
  transform: translateY(0);
}

iframe#launcher {
  z-index: 50 !important;
}