// TODO Add inline display and other flex classes

// Padding and Margin
@import "./margin.scss";
@import "./padding.scss";

// Button
@import "./button.scss";

// Text size
@import "./text-size.scss";

// Table
@import "./table.scss";

// Display
.d {
    &-block {
        display: block !important;
    }
    &-none {
        display: none !important;
    }
    &-flex {
        display: flex;
    }

    // Responsive
    // block
    &-sm-block {
        @include sm {
            display: block !important;
        }
    }
    &-md-block {
        @include md {
            display: block !important;
        }
    }
    &-lg-block {
        @include lg {
            display: block !important;
        }
    }
    &-xl-block {
        @include xl {
            display: block !important;
        }
    }

    // none
    &-sm-none {
        @include sm {
            display: none !important;
        }
    }
    &-md-none {
        @include md {
            display: none !important;
        }
    }
    &-lg-none {
        @include lg {
            display: none !important;
        }
    }
    &-xl-none {
        @include xl {
            display: none !important;
        }
    }

    // flex
    &-sm-flex {
        @include sm {
            display: flex !important;
        }
    }
    &-md-flex {
        @include md {
            display: flex !important;
        }
    }
    &-lg-flex {
        @include lg {
            display: flex !important;
        }
    }
    &-xl-flex {
        @include xl {
            display: flex !important;
        }
    }
}

.justify-content {
    &-between{
        justify-content: space-between;
    }
    &-center{
        justify-content: center;
    }
    &-end{
        justify-content: flex-end;
    }
    &-around{
        justify-content:space-around;
    }
}
.align-items {
    &-center {
        align-items: center !important;
    }
    &-stretch {
        align-items: stretch !important;
    }
    &-start {
        align-items: flex-start !important;
    }
    &-end {
        align-items: flex-end !important;
    }
}
.align-content {
    &-center {
        align-content: center !important;
    }
    &-stretch {
        align-content: stretch !important;
    }
    &-start {
        align-content: flex-start !important;
    }
    &-end {
        align-content: flex-end !important;
    }
}
.align-self {
    &-center {
        align-self: center !important;
    }
    &-stretch {
        align-self: stretch !important;
    }
    &-start {
        align-self: start !important;
    }
    &-end {
        align-self: end !important;
    }
}

.flex-direction{
    &-column{
        flex-direction: column;
    }
}

.flex{
    &-start{
        align-self: flex-start;
    }
    &-end{
        align-self: flex-end;
    }
}

.flex-grow-1{
    flex-grow: 1;
}

// Position
.position {
    &-relative {
        position: relative !important;
    }
    &-absolute {
        position: absolute !important;
    }
}

.text-decoration {
    &-none {
        text-decoration: none !important;
    }
}

.render {
    &.moduleNotFound {
        border: 2px solid #ff0000;
        background-image: linear-gradient(135deg, #ff8a8a 25%, #F6F0CF 25%, #F6F0CF 50%, #ff8a8a 50%, #ff8a8a 75%, #F6F0CF 75%, #F6F0CF 100%);
        background-size: 56.57px 56.57px;
        p {
            padding: 50px;
            text-align: center;
            background-color:rgba(255, 0, 0, 0.4);
            color: #fff;
            font-family: "Helvetica";
            font-size: 20px;
        }
    }
}

.overflow-y-auto{
    overflow-y: auto;
}

