@import '/static/fonts/poppins/font.css';

.Poppins {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.font-weight {
  &-bold {
    font-weight: 700;
  }

  &-600 {
    font-weight: 600;
  }

  &-500 {
    font-weight: 500;
  }
}

.line-h-1 {
  line-height: 1;
}

.section-main-title {
  @extend .Poppins;
  color: map-get($colors, green02);
  font-size: map-get($font-size, '30');
  font-weight: 600;
  @include md {
    font-size: map-get($font-size, '40');
  }
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(map-get($colors, 'grey05'), 0.4);
}

.fix-font-margin {
  margin-bottom: -5px;
}
