@use "sass:math";

$paddingMobile: 12px;
$paddingDesktop: 8px;

// $grid__bp-sm: 540;
// $grid__bp-md: 740;
// $grid__bp-lg: 960;
// $grid__bp-xl: 1140;
// $grid__bp-xxl: 1540;

$grid__bp-sm: 540;
$grid__bp-md: 740;
$grid__bp-lg: 960;
$grid__bp-xl: 1140;
$grid__bp-xxl: 1280;

$grid__cols: 24;
$grid__colsx25: 25;

$map-grid-props: (
  "": 0,
  "-sm": $grid__bp-sm,
  "-md": $grid__bp-md,
  "-lg": $grid__bp-lg,
  "-xl": $grid__bp-xl,
  "-xxl": $grid__bp-xxl,
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

// Standard cols systems 24
@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, "min") {
    .col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    // 24 cols
    @for $i from 1 through $grid-cols {
      .col#{$modifier}-#{$i} {
        flex-basis: (100/($grid-cols/ $i))*1%;
        
        max-width: (100/($grid-cols / $i))*1%;
      }
      .col#{$modifier}-offset-#{$i} {
        margin-left: (100/($grid-cols/ $i))*1%;
        
      }
    }
  }
}

// Custom cols system 25
@mixin create-col-classesx25($modifier, $grid-colsx25, $breakpoint) {
  @include create-mq($breakpoint, "min") {
    .colx25#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-colsx25 {
      .colx25#{$modifier}-#{$i} {
        flex-basis: (100/($grid-colsx25/ $i))*1%;
        max-width: (100/($grid-colsx25/ $i))*1%;
      }
      .colx25#{$modifier}-offset-#{$i} {
        margin-left: (100/($grid-colsx25/ $i))*1%;
      }
    }
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  // @if ($modifier == "") {
  //   $modifier: "-xs";
  // }
  @include create-mq($breakpoint - 1, "max") {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, "min") {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  max-width: 100%;
  margin: 0 auto;
  @include sm {
    max-width: $grid__bp-sm * 1px;
  }
  @include md {
    max-width: $grid__bp-md * 1px;
  }
  @include lg {
    max-width: $grid__bp-lg * 1px;
  }
  @include xl {
    max-width: $grid__bp-xl * 1px;
  }
  @include xxl {
    max-width: $grid__bp-xxl * 1px;
  }
  &-fluid {
    margin: 0;
    max-width: 100%;
    .row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    // Cols24
    @each $modifier, $breakpoint in $map-grid-props {
      @include create-col-classes($modifier, $grid__cols, $breakpoint);
    }

    // Cols 25
    @each $modifier, $breakpoint in $map-grid-props {
      @include create-col-classesx25($modifier, $grid__colsx25, $breakpoint);
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  // Cols24
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }

  // Cols 25
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classesx25($modifier, $grid__colsx25, $breakpoint);
  }
}

// [class*="col-"] {
//   padding-left: $paddingMobile;
//   padding-right: $paddingMobile;
//   @include md {
//     padding-left: $paddingDesktop;
//     padding-right: $paddingDesktop;
//   }
// }

.row-justify-content-around {
  justify-content: space-around;
}
.row-justify-content-around{
  justify-content: center;
}
.row-justify-content-end{
  justify-content: flex-end;
}
