$colors: (
  "green03": #005c69,
  "green02": #00828c,
  "green01": #00ada0,

  "rose01": #FF3366,
  "rose02": #FF3366,
  "rose03": #FF3366,
  "roseBtn": #FF3366,

  "yellow01": #ffd759,
  "yellow02": #dfb623,
  "yellow03": #c59d0a,
  "yellowBtn": #ffbd08,

  // -- hover
  "yellow01hover": #c9a00b,

  "red01": #ff6671,
  "red02": #cb606d,
  "red03": #b0474f,

  "blue01": #598dff,
  "blue02": #396dcf,
  "blue03": #1138ab,

  "violet01": #b0598d,
  "violet02": #8b3a6a,
  "violet03": #850d63,

  "pink01": #ff6671,

  "grey01": #fbfbfb,
  "grey02": #f2f2f2,
  "grey03": #d2d3d6,
  "grey04": #808080,
  "grey05": #3b4154,
  "white": #fff,
);

@each $key, $value in $colors {
  .text-#{$key} {
    color: #{$value};
  }

  .bg-#{$key} {
    background-color: #{$value};
  }

  // Settings used in Icon components
  .fill-#{$key} {
    fill: none;
    .fill {
      fill: #{$value};
    }
    .stroke {
      stroke: #{$value};
    }
  }

  .border-#{$key} {
    border: 1px solid #{$value};
  }
}
